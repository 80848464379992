.container {
    text-align: center;
    margin: 10px;
}

.button {
    padding: 10px 15px;
    font-size: 14px;
}

.heading {
    font-size: 24px;
    color: #333;
}