.Homepage {
    padding: 64px;
    min-height: calc(50vh);
    display: flex;
    flex-direction: column;
    gap: 32px;
    background: linear-gradient(to right, #031d60, #03267c);
}

.form-header.home{
    min-height: unset;
    display: flex;
    flex-direction: column;
    gap: 24px; 
    font-size: 48px; 
    color: #174074;
    background: none;
    background-color: white !important;
    font-weight: 400;
  }
  
  .logo.home{
    height: 150px;
  }


.viewStatusDiv{
    width: 100%;
    max-width: 900px;
    align-self: center;
    background-color: white;
    border: none;
    border-radius: 1rem;
    transition: all 0.2s;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    padding: 24px 32px 32px;
}

.viewStatusHead{
    text-transform: uppercase;
    font-weight: 700;
    color: black;
    opacity: 0.7;
    letter-spacing: 0.05rem;
}

.viewStatusFlex{
    display: flex;
    /* flex-direction: column; */
    gap: 40px;
    margin-top: 24px;
}

.home-button {
    font-size: 0.8rem;
}

.Homepage input {
    padding: 10px;
    border-radius: 0.25rem;
    border: 1px solid #dce7f1;
    /* width: calc(100% - 20px); */
    font-size: 0.8rem !important;
    color: #607080;
    flex-grow: 1;
}
