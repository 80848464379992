.Dashboard {
    display: flex;
    padding: 32px 64px;
    padding-left: 0;
    gap: 64px;
}

.sideBar {
    margin-top: 8px;
    padding: 32px;
   
    background-color: #031d60;
    
    border: none;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    transition: all 0.2s;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    min-width: 250px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    position: sticky;
    top: 64px;
    bottom: 32px;
    height: calc(64vh);
}

.sidebarItem {
  
    color: white;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    transition: all 300ms ease-in-out, color 300ms ease-in-out;
    letter-spacing: 0.025rem;
}

.sidebarItem:hover {
    color: #eea412;
}

.sidebarItem.selected {
    color: #eea412;
}

.mainBar {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.mainBar.approvalPage{
    max-width: 960px;
    margin: 0 auto;
    padding-left: 64px;
}

.dashMainHeading {

    color: rgb(29, 29, 29);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    letter-spacing: 0.025rem;
}

.dashHeadingFlex{
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
}

.dashHeading {
   
    color: rgb(29, 29, 29);

    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.025rem;
}

.backBtn{
    color: #031d60;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 4px;
}

.approvalsContainer {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 14px;
}

.approval {
    padding: 20px;
    background-color: white;
    border: none;
    border-radius: 1rem;
    transition: all 300ms ease-in-out;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: 1fr auto auto auto;
    gap: 12px;
    align-items: center;
}

.approvalContent {
    color: rgb(29, 29, 29);
    
}

.viewPDFflex{
    display: grid;
    grid-template-columns: 30px 1fr 80px 80px;
    gap: 0px;
    align-items: center;
    margin: 0px 0;
    min-height: 36px;
}

.viewpdfSno{
    height: 100%;
    border: 0.5px solid #000a972c;
    display: flex;
    align-items: center;
    justify-content: center;
}

.viewpdfLabel{
    padding: 0px 16px;
    height: 100%;
    border: 0.5px solid #000a972c;
    border-left: 0.5px solid #000a9710;
    display: flex;
    align-items: center;
}

.viewPDFviewBtn{
    height: 100%;
    border: 0.5px solid #000a972c;
    border-left: 0.5px solid #000a9710;
    display: flex;
    align-items: center;
    justify-content: center;
}

.viewPDFflex button, .viewPDFapproveBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: auto;
}

.formDataDiv{
    padding: 20px;
    background-color: white;
    border: 0.5px solid #0000001a;
    border-radius: 1rem;
    transition: all 300ms ease-in-out;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
 
    color: rgb(29, 29, 29);
   
}
.formDatagrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    
}

.pdfHead{
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.025rem;
    margin: 40px 0 16px;
}

.approveBtn{
    background-color: rgb(31, 177, 104);
}

.approveBtn:hover{
    background-color: rgb(0, 138, 92);
}

.approveBtn.approved{
    background-color: rgb(0, 138, 92);
    cursor: not-allowed;
}

.approvedLabel{
    padding: 6px 12px;
    font-size: 14px;
    background-color: rgb(200, 245, 222);
    color: rgb(34, 117, 75);
    border-radius: 4px;
    display: flex;
    justify-content: center;
}

.rejectedLabel{
    background-color: rgb(243, 199, 223);
    font-size: 14px;
    padding: 6px 12px;
    color: rgb(187, 0, 0);
    border-radius: 4px;
    display: flex;
    justify-content: center;
}

.StatusGrid{
    display: grid;
    grid-template-columns: 100px 150px;
    gap: 8px;
    margin: 4px;
}
.StatusGrid>strong{
    display: flex;
    align-items: center;
}

.ShowUploadCertiModalContainer{
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.411);
    display: flex;
    justify-content: center;
    align-items: center;
}

.ShowUploadCertiModal{
    background-color: white;
    padding: 10px 24px 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 12px;
    box-shadow: 0px 5px 10px #0000001a;
}

.closeShowUploadCertiModal{
    text-align: right;
    font-size: 24px;
    cursor: pointer;
}
.ShowUploadCertiHead{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 16px;
}

.uploadCertiInput{
    border: 0.5px solid #0000005b;
    padding: 5px 10px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.presidentInput{
    padding: 6px 12px;
    border-radius: 8px;
    border: 0.5px solid #0000001a;
}

.presidentObserverDiv{
    margin: 40px auto 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    gap: 16px;
    background-color: #5bc0ff43;
    border-radius: 8px;
    padding: 16px;
}
.presidentObserverDiv label{
    margin-right: 10px;
}


/* UPDATED CSS STYLES */








/* General Styles */
.adminDashboard {
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #f9f9f9;
    padding: 20px;
}

.dashboardWrapper {
    max-width: 1200px;
    margin: auto;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

/* Header Section */
.dashboardHeader {
    text-align: center;
    margin-bottom: 20px;
}

.dashboardTitle {
    font-size: 2rem;
    font-weight: bold;
    color: #4a90e2;
}

/* Navigation */
.dashboardNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.dashboardHeading {
    font-size: 1.5rem;
    font-weight: bold;
    color: #555;
}

.backButton {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    color: #4a90e2;
    font-size: 1rem;
}

.backButton:hover {
    text-decoration: underline;
}

.backIcon {
    font-size: 1.2rem;
}

/* Form Details Section */
.formDetailsSection {
    background: #fdfdfd;
    padding: 20px;
    border: 1px solid #eee;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.formDetailsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.formDetailsRow {
    font-size: 0.9rem;
    background: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
}

/* Documents Section */
.documentsSection {
    margin-top: 20px;
}

.sectionTitle {
    font-size: 1.2rem;
    font-weight: bold;
    color: #555;
    margin-bottom: 10px;
}

.documentsList {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.documentRow {
    display: flex;
    align-items: center;
    gap: 20px;
    background: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08); /* Soft shadow */
    background: #eef4fb; /* Light blue for document rows */
}

.documentIndex {
    font-weight: bold;
    color: #4a90e2;
}

.documentLabel {
    flex: 1;
    font-size: 0.9rem;
    color: #333;
}

.viewButton{
    padding: 6px 18px;
}

.approveButton {
    background: linear-gradient(135deg, #4caf50, #66bb6a); /* Green gradient */
    color: white;
    border: none;
    padding: 6px 18px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.95rem;
    transition: all 0.3s ease;
}


.approveButton:hover {
    background: linear-gradient(135deg, #388e3c, #56a95c); /* Darker green on hover */
    transform: scale(1.05);
}

/* Approved Button */
.approvedButton {
    background: #4caf50;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: default;
}

.approvedButtonContainer {
    margin-left: auto;
}

/* Approval Status Section */
.approvalStatusSection {
    margin-top: 30px;
}

.statusGrid {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.statusRow {
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
    padding: 8px;
    background: #fdfdfd;
    border: 1px solid #eee;
    border-radius: 5px;
}

.statusApproved {
    color: #4caf50;
    font-weight: bold;
}

.statusNotApproved {
    color: #f44336;
    font-weight: bold;
}

/* Approve All Button */
.approveAllButton {
    background: linear-gradient(135deg, #4caf50, #66bb6a); /* Green gradient */
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Button shadow */
    transition: all 0.3s ease;
}

.approveAllButton:hover {
    background: linear-gradient(135deg, #388e3c, #56a95c); /* Darker green on hover */
    transform: scale(1.05);
}

/* Approval Label */
.approvalLabel {
    text-align: center;
    font-size: 1rem;
    color: #4caf50;
    font-weight: bold;
}





