.formPage {
  padding: 0 0px 64px;
  background-color: #F2F6FF;
}

.form-header {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 64px; 
  font-size: 48px; 
  color: white;
  background: linear-gradient(to right, #031d60, #03267c);
  padding: 20px 64px;
  text-align: center;
}

.logo {
  height: 120px;
}

.formPageContainer{
  padding: 32px;
  display: flex;
  flex-direction: column;
  /* gap: 40px; */
  width: calc(100% - 64px);
}


/* form css written below */


.form-container {
  margin: 16px 0px;
  padding: 32px 40px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: rgba(35, 28, 99, .7);
  font-size: 0.8rem;
  align-self: center;
  max-width: 1000px;
}

.form-container> form{
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.formSubHead{
  font-size: 28px;
  font-weight: 700;
  margin: 48px 0px 0;
  padding: 0px 0px;
  border-radius: 12px;
  color: rgba(35, 28, 99, .7);
  align-self: center;
  width: 100%;
  max-width: 1080px;
}


.formWrap{
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
}
/* .formWrap>.form-row{
  width: calc(50% - 20px);
} */

.formWrap3{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.form-row {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: space-between;
  position: relative;
}

/* this css is used in radio buttons, international faculty */
.form-flex {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
}

.form-flex input {
  width: unset !important;
}

.form-row label {
  /* margin-bottom: 5px; */
  font-weight: 600;
  font-size: 14px;
}

.form-row input {
  padding: 10px 12px;
  border-radius: 0.25rem;
  border: 1px solid #dce7f1;
  width: calc(100% - 24px);
  font-size: 0.8rem !important;
  background-color: #F2F6FF;
  color: #607080;
}

.form-row textarea {
  padding: 6px 12px;
  border-radius: 0.25rem;
  border: 1px solid #dce7f1;
  background-color: #F2F6FF;
  max-width: calc(100% - 24px);
  font-size: 0.8rem !important;
  color: #607080;
}

/* .form-row select {
  padding: 6px 12px;
  border-radius: 0.25rem;
  border: 1px solid #dce7f1;
  width: calc(100% - 24px);
  font-size: 0.8rem !important;
  color: #607080;
} */

.faculty-inputs input {
  padding: 6px 12px;
  border-radius: 0.25rem;
  border: 1px solid #dce7f1;
  width: calc(100% - 24px);
  font-size: 0.8rem !important;
  color: #607080;
}


.submit-button {
  font-size: 20px;
  width: 100%;
  margin-top: 48px;
}
.test-button {
  font-size: 16px;
  width: fit-content;
  margin-top: 0px;
}

.faculty-inputs input {
  margin-bottom: 10px;
}

/* .addTopicBtn {
  margin-top: 10px;
  border: 1px solid #435ebe;
  color: white;
  padding: 4px 8px;
  font-weight: 500;
  border-radius: 4px;
  background-color: #435ebe;
  cursor: pointer;
} */

.date_input_div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.date_input_div_inner {
  width: calc(100%);
  font-size: 14px !important;

}

.dual_column_input {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}



.error{
  color: red !important;
  position: absolute;
  bottom: -18px;
}

.info{
  color: rgb(0, 0, 0) !important;
  position: absolute;
  bottom: -18px;
}

.file-input{
  padding: 3px 12px !important;
}

input#file-upload-button{
  color: red !important;
  background-color: red !important;
}




/* below code is for new deign of upload */


/* OTP Section Styles */
.otpSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.otpMessage {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  text-align: center;
  margin-bottom: 15px;
}

.otpInput {
  padding: 10px;
  font-size: 16px;
  margin-top: 10px;
  width: 80%;
  max-width: 300px;
  border: 2px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
}

.otpInput:focus {
  border-color: #007bff;
}

.otpSubmitButton {
  background-color: #007bff;
  color: white;
  font-size: 16px;
  padding: 14px 26px;
  margin-top: 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.otpSubmitButton:hover {
  background-color: #0056b3;
}
