/* ViewStatus.css */
.ViewStatusPage {
    padding: 64px;
    height: 100vh; /* Fixed height to fill the entire viewport */
    display: flex;
    justify-content: center;
    background-color: #f4f7fc; /* Background color for the page */
}

.ViewStatus {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 800px;
    flex-grow: 1;
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* Ensures the table stays within the bounds */
    max-height: calc(100vh - 128px); /* Prevents the container from growing too large */
}

.dashHeading {
    font-size: 24px;
    font-weight: bold;
    color: #2b3d51;
    margin-bottom: 16px;
    text-align: center;
}

.status-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 24px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    overflow-x: auto; /* Enables horizontal scrolling if needed */
}

.status-table th,
.status-table td {
    padding: 12px 16px;
    text-align: left;
    border: 1px solid #e1e1e1;
}

.status-table th {
    background-color: #e6f7ff;
    color: #007bff;
    font-weight: 600;
}

.status-table td {
    background-color: #fafafa;
}

.status-table tr:nth-child(even) td {
    background-color: #f9f9f9;
}

.status-table tr:hover td {
    background-color: #f1f1f1;
}

.error-message {
    color: red;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
}

.loading-indicator {
    text-align: center;
    font-size: 18px;
    color: #007bff;
}

.otp-page {
    text-align: center;
    margin-top: 30px;
}

.otp-page input {
    padding: 8px;
    margin-bottom: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.otp-page button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.otp-page button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
