.App {
  text-align: center;
}


.form_parent{
  display: flex;
  flex-direction: column;
/* grid-template-rows: 1fr; */
}

.form_row{
  display: flex;
  
}

.serial_no{
  min-width: 30px;
}


button {
  background-color: #03267c;
  color: white;
  border: none;
  cursor: pointer;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
  padding: 8px 16px;
  border-radius: .3rem;
  text-align: center;
}

button:hover {
  background-color: #435ebe;
}

button[disabled]{
  background-color: #607080;
  cursor: not-allowed;
}

a{
  text-decoration: none;
  color: inherit;
}